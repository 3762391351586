import { PolymerElement, html } from '@polymer/polymer/polymer-element.js';

import '@polymer/paper-spinner/paper-spinner.js';
import '@cbar/cbar-shared-styles/cbar-shared-styles.js';
import '@material/web/dialog/dialog.js';

/**
 * `cc-add-to-entity`
 *
 * A web component for assigning a profile to an entity such as a group or event.
 * The component provides a dialog interface for selecting entities and relationships.
 *
 * Features:
 * - Displays a dialog with entity selection.
 * - Validates inputs before submission.
 * - Handles loading states and error messages.
 * - Resets states between triggers for reuse.
 *
 * @customElement
 * @polymer
 * @demo demo/index.html
 */
class CCAddToEntity extends PolymerElement {
    static get template() {
        return html`
    <style include="cbar-shared-styles">
      :host {
        display: block;

        --cbar-date-time-picker-label-buttons: {
            justify-content: flex-end;
        };
      }

      cbar-entity-relationship-selector {
          --mdc-theme-primary: var(--cbar-sys-color-primary, #004CA2);
          --mdc-text-field-fill-color: transparent;
          --mdc-select-fill-color: transparent;
          --mdc-text-field-ink-color: var(--cbar-text-field-ink-color, var(--cbar-sys-color-on-surface, black));
          --mdc-text-field-disabled-ink-color: var(--cbar-text-field-disabled-ink-color, var(--cbar-sys-color-on-surface-variant, grey));
          --mdc-text-field-label-ink-color: var(--cbar-text-field-label-ink-color, var(--cbar-sys-color-primary, #004CA2));

          width: 100%;
      }
      
      [hidden] {
        display: none;
      }
    </style>

    <md-dialog with-backdrop open="{{opened}}" on-close="__closeHandler">
       <h6 slot="headline">[[profileTitle]]</h6>
        
      <cbar-entity-relationship-selector
        slot="content"  
        id="entityRelationshipSelector"
        entities="[[entities]]"
        entity-type="[[entityType]]"
        disabled$="[[loading]]"
        entity-required
        relationship-required 
        context-entity-id="[[contextEntityId]]" 
        relationships-label="Position">
      </cbar-entity-relationship-selector>

      <div slot="actions">
        <p hidden="[[!_errorMessage]]">[[_errorMessage]]</p>
        <paper-button hidden$="[[loading]]" class="btn-cancel" on-tap="close">Cancel</paper-button>
        <paper-button hidden$="[[loading]]" class="btn-assign" on-tap="assign">Assign</paper-button>

        <paper-spinner active="[[loading]]" hidden$="[[!loading]]"></paper-spinner>
      </div>
    </md-dialog>
    `;
    }

    static get is() {
        return 'cc-add-to-entity';
    }

    static get properties() {
        return {
            /**
             * Whether the dialog is opened or not.
             *
             * @type {Boolean}
             * @default false
             */
            opened: {
                type: Boolean,
                notify: true,
                value: false,
            },

            /**
             * A map of entityType to entities array, where each entity is an object.
             *
             * @type {Object}
             */
            entities: Object,

            /**
             * The type of entity the user is assigning to (e.g., "Group" or "Event").
             *
             * @type {String}
             */
            entityType: String,

            /**
             * The ID of the context entity for the relationship (e.g., group ID).
             *
             * @type {String}
             */
            contextEntityId: String,

            /**
             * The title to display in the dialog header.
             *
             * @type {String}
             */
            profileTitle: String,

            /**
             * The ID of the profile to assign the role to.
             *
             * @type {Number}
             */
            profileId: Number,

            /**
             * Indicates whether the component is in a loading state.
             *
             * @type {Boolean}
             * @default false
             */
            loading: {
                type: Boolean,
                value: false,
            },

            /**
             * An error message displayed when validation or submission fails.
             *
             * @type {String}
             */
            _errorMessage: String,
        };
    }

    /**
     * Called when the component is ready. Initializes fixes for dialog overflow.
     */
    ready() {
        super.ready();
        requestAnimationFrame(() => this.handleOverflowIssue());
    }

    /**
     * Returns the `md-dialog` instance in the component's shadow DOM.
     *
     * @returns {HTMLElement} The dialog element.
     */
    get dialog() {
        if (!this._dialog) {
            this._dialog = this.root.querySelector('md-dialog');
        }
        return this._dialog;
    }

    /**
     * Opens the dialog and resets any previous state.
     */
    open() {
        this.opened = true;
        this.$.entityRelationshipSelector.reset();
        requestAnimationFrame(() => this.handleOverflowIssue());
    }

    /**
     * Closes the dialog and resets relevant states.
     */
    close() {
        this.opened = false;
        this.loading = false;
        this._errorMessage = null;

        // Reset the entity relationship selector
        this.$.entityRelationshipSelector.reset();
    }

    /**
     * Handles the assignment action. Validates inputs, submits the form,
     * and emits a `role-added` event upon success.
     *
     * @returns {Promise} Resolves with the server response on success.
     */
    assign() {
        let entityRelationshipEle = this.$.entityRelationshipSelector;

        if (!entityRelationshipEle.validate()) {
            return;
        }

        this._errorMessage = null;
        this.loading = true;

        let data = {
            action: 'add',
            source_entity: 'Profile',
            source_entity_id: this.profileId,
            target_entity: this.entityType,
            target_entity_id: entityRelationshipEle.selectedEntity.id,
            quota: entityRelationshipEle.selectedRelationship.id,
        };

        let { selectedStartDate, selectedEndDate } = entityRelationshipEle;
        if (selectedStartDate) {
            data.start_time = moment(selectedStartDate).format('DD-MM-YYYY HH:mm');
        }
        if (selectedEndDate) {
            data.end_time = moment(selectedEndDate).format('DD-MM-YYYY HH:mm');
        }

        return new Promise((res, rej) => {
            $.ajax({
                url: '/ajax/entity-roles',
                type: 'POST',
                dataType: 'json',
                data,
                success: data => {
                    this.loading = false;
                    if (data.error) {
                        this._errorMessage = data.message;
                        return rej(data.message);
                    }

                    this.dispatchEvent(new CustomEvent('role-added', {
                        bubbles: true,
                        composed: true,
                        detail: data,
                    }));

                    this.close();
                    return res(data);
                },
                error: xhr => {
                    this.loading = false;
                    this._errorMessage = xhr.responseJSON ? xhr.responseJSON : xhr.responseText;
                    rej(this._errorMessage);
                },
            });
        });
    }

    /**
     * Prevents the dialog from closing while loading is active.
     *
     * @param {Event} e The close event.
     */
    __closeHandler(e) {
        if (this.loading) {
            e.preventDefault();
        } else {
            this.close();
        }
    }

    /**
     * Fixes overflow issues for the dialog container and scroller.
     */
    handleOverflowIssue() {
        this.dialog.container.style.overflow = 'visible';
        this.dialog.scroller.style.overflow = 'visible';
    }
}

window.customElements.define(CCAddToEntity.is, CCAddToEntity);