import {css, PropertyValues} from 'lit';
import {property} from 'lit/decorators.js';
import {TextField} from '@material/mwc-textfield';

import {applyDenseStylesheet, disableDenseStylesheet} from "./dense-fix";

/**
 * `CbarTextfield` is a Lit-based extension of `mwc-textfield` that introduces
 * additional properties and styling enhancements.
 *
 * Features:
 * - Supports `dense` mode, dynamically applying or removing dense styles.
 * - Optionally hides validation errors when the field gains focus.
 * - Ensures proper label positioning when the component becomes visible.
 * - Customizable styling via CSS properties.
 *
 * @element cbar-textfield
 * @extends TextField
 */
export class CbarTextfield extends TextField {
  /**
   * Defines the component's styles, extending the base styles from `mwc-textfield`.
   */
  static styles = [
    ...TextField.styles,
    css`
      :host([hidden]) {
        display: none;
      }

      :host {
        width: 100%;
        --mdc-text-field-filled-border-radius: var(--cbar-text-field-filled-border-radius);
        --mdc-text-field-idle-line-color: var(--cbar-text-field-idle-line-color, var(--cbar-sys-color-primary));
        --mdc-text-field-hover-line-color: var(--cbar-text-field-hover-line-color, var(--cbar-sys-color-on-surface));
        --mdc-text-field-disabled-line-color: var(--cbar-text-field-disabled-line-color, var(--cbar-sys-color-on-surface-variant));
        --mdc-text-field-outlined-idle-border-color: var(--cbar-text-field-outlined-idle-border-color, var(--cbar-sys-color-primary));
        --mdc-text-field-outlined-hover-border-color: var(--cbar-text-field-outlined-hover-border-color, var(--cbar-sys-color-on-surface));
        --mdc-text-field-outlined-disabled-border-color: var(--cbar-text-field-outlined-disabled-border-color, var(--cbar-sys-color-on-surface));
        --mdc-text-field-fill-color: var(--cbar-text-field-fill-color, var(--cbar-sys-color-surface-container-highest, transparent));
        --mdc-text-field-disabled-fill-color: var(--cbar-text-field-disabled-fill-color, var(--cbar-sys-color-surface-container-lowest));
        --mdc-text-field-ink-color: var(--cbar-text-field-ink-color, var(--cbar-sys-color-on-surface, black));
        --mdc-text-field-label-ink-color: var(--cbar-text-field-label-ink-color, var(--cbar-sys-color-primary, #004CA2));
        --mdc-text-field-disabled-ink-color: var(--cbar-text-field-disabled-ink-color, var(--cbar-sys-color-on-surface-variant, grey));
      }
    `
  ];

  /**
   * Enables or disables dense mode, affecting the field's size and layout.
   */
  @property({type: Boolean})
  dense = false;

  /**
   * Determines whether validation errors should be hidden when the input gains focus.
   */
  @property({type: Boolean})
  hideErrorsOnFocus = false;

  /**
   * Constructor initializes the component and sets up visibility tracking.
   */
  constructor() {
    super();

    // Recalculate layout when the textfield becomes visible.
    // Fixes https://github.com/material-components/material-web/issues/2915
    this.respondToVisibility();
  }

  /**
   * Lifecycle method triggered when properties are updated.
   *
   * @param {PropertyValues} changedProperties - The properties that have changed.
   */
  public updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);

    if (changedProperties.has('dense')) {
      requestAnimationFrame(() =>
        this.dense ? applyDenseStylesheet(this) : disableDenseStylesheet(this)
      );
    }
  }

  /**
   * Overrides the default focus behavior to optionally hide validation errors.
   */
  protected onInputFocus() {
    super.onInputFocus();

    if (this.hideErrorsOnFocus) {
      // @ts-ignore - Accesses internal MDC Foundation method.
      this.mdcFoundation.styleValidity(true);
    }
  }

  /**
   * Uses an `IntersectionObserver` to detect when the component becomes visible
   * and ensures the text field layout updates correctly.
   */
  respondToVisibility() {
    const options = {
      root: document.body,
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Re-calculate layout to ensure label on outlined textfield
          // is properly displayed
          this.layout();
        }
      });
    }, options);

    observer.observe(this);
  }
}
